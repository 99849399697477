import React from "react";

// src={`https://vimeo.com/event/${videoId}/embed`}

export default function Video({ videoId }) {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${videoId}`}
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      style={{
        width: "100%",
        height: "100%"
      }}
    ></iframe>
  );
}
